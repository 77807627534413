import { DateInput } from 'components/designSystem/DateInput';
import { useEffect, useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { StatusInput } from 'types';
import { checkFormatDate, getDateFromInput, isDateIntheFuture } from 'utils/DateUtils';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface LoanStartingDateProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const LoanStartingDate: React.FC<LoanStartingDateProps> = ({
  currentLoan,
  state,
  dispatch,
}) => {
  const [startingDate, setStartingDate] = useState<string>(
    currentLoan?.loanStartingDate || '',
  );
  const [statusStartingDate, setStatusStartingDate] = useState<StatusInput>();
  const [reloadStatusStartingDate, setReloadStatusStartingDate] =
    useState<boolean>(false);

  const onDateChange = (e: Event) => {
    setStartingDate((e.target as HTMLInputElement).value);
  };

  const checkStatusStartingDate = () => {
    if (reloadStatusStartingDate === true) {
      setReloadStatusStartingDate(false);
      if (startingDate === '' || startingDate === undefined) {
        setStatusStartingDate({
          status: 'none',
          errorMessage: '',
        });
        return;
      }

      if (startingDate && !checkFormatDate(startingDate)) {
        setStatusStartingDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.DATE_FORMAT,
        });
        return;
      }
      if (!isDateIntheFuture(getDateFromInput(`${startingDate}`))) {
        setStatusStartingDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.DATE_FUTUR,
        });
        return;
      }
      setStatusStartingDate({
        status: 'valid',
        errorMessage: ``,
      });
    }
  };

  useEffect(() => {
    checkStatusStartingDate();
  }, [reloadStatusStartingDate]);

  const onDateBlur = () => {
    setStatusStartingDate({
      status: 'none',
      errorMessage: '',
    });
    setReloadStatusStartingDate(true);
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return { ...item, loanStartingDate: startingDate };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  return (
    <DateInput
      class="show-input-ds-validation"
      label="Date de début du prêt (Facultatif)"
      onBlur={onDateBlur}
      onInputChange={onDateChange}
      value={startingDate}
      {...(statusStartingDate?.status !== 'none'
        ? { status: statusStartingDate?.status }
        : {})}
      {...(statusStartingDate?.errorMessage !== ''
        ? { 'error-message': statusStartingDate?.errorMessage }
        : {})}
    />
  );
};

export default LoanStartingDate;
